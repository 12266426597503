import axios from 'axios';


const superApi = axios.create({
    
})

switch (process.env.NODE_ENV) {
    case 'production':
        superApi.defaults.baseURL = 'https://twbc2.websoftware.nz/'
        break
    case 'development':
        superApi.defaults.baseURL = 'http://192.168.86.24:5000/'
        // superApi.defaults.baseURL = 'https://twbc2.websoftware.nz/'
        break

    default:
        superApi.defaults.baseURL = 'http://localhost:5000/'

}

superApi.interceptors.request.use(config => {
    const token = window.localStorage.getItem('authBody');
    if (token && token !== 'null') {
        config.auth = {
            username: token,
            password: ''
        }
    }

    return config;
}) 
superApi.interceptors.response.use((response) => {

    
    return response;
},(error) => {
    if(error.response) {

        if(error.response.status === 401) {
            window.localStorage.removeItem('auth');
            window.localStorage.setItem('expired', true);
            window.localStorage.removeItem('authBody');
            
        }
    } else {
        console.log(error)
    }
    
    return Promise.reject(error);
    
})

export default superApi;
