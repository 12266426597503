import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component'
import superApi from '../../Api/Api';
import { Link } from 'react-router-dom';
import Moment from 'react-moment';
import './PointsTable.css'
import {
    Card, CardImg, CardText, CardBody,
    CardTitle, CardSubtitle, Button
} from 'reactstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import { CSVLink, CSVDownload } from "react-csv";
import { addDays } from 'date-fns';
var moment = require('moment');

const ExpanableComponent = (props) => {
    return (
        <Card className="point">
            <CardBody>
                <CardTitle className="point-contender">{props.data.contender.profile.fullname}</CardTitle>
                <CardSubtitle className="point-activity">{props.data.activity.title}</CardSubtitle>
                <CardText className="point-note">{props.data.notes}</CardText>
            </CardBody>
        </Card>
    )
}



const PointsTable = (props) => {

    const [points, setPoints] = useState([])
    // const [activities, setActivities] = useState([])
    const [reload, setReload] = useState(true);
    const [rawData, setRawData] = useState([]);
    const [showFilter, setShowFilter] = useState(false)
    const [leaderBoard, setLeaderBoard] = useState([])
    const [downloadCsv, setDownloadCsv] = useState(false)
    const [dateRangeSelection, setDateRangeSelection] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    })
    const [loading, setLoading] = useState(true)
    const csvHeader = [
        "Name","Day","Category","Activity","Notes","Points"
    ]
    const csvData = points.map(point => {
        return [
            point.contender.profile.fullname,
            point.day,
            point.category,
            point.activity.title,
            point.notes,
            point.points
        ]
    })

    csvData.splice(0,0,csvHeader)

    useEffect(() => {
        superApi.get('task')
            // .then(response => setActivities(response.data._items))
            .then(() => superApi.get('points?where=' + props.where + '&embedded={"contender":1, "activity": 1}').then(response => {
                console.log(response.data._items)
                response.data._items.forEach(item => {
                    if (item.contender === null) {
                        item.contender = {
                            "profile": {
                                "fullname": "Deleted User",
                                "first_name": "Deleted",
                                "last_name": "User"
                            }
                        }
                        return
                    }
                    if (item.contender.profile.first_name === undefined) {
                        item.contender.profile.first_name = 'Unknown'
                        item.contender.profile.fullname = item.contender.username
                    }

                    else if (item.contender.profile.last_name === undefined) {
                        item.contender.profile.last_name = 'Unknown'
                        item.contender.profile.fullname = item.contender.username
                    }
                    else {

                        item.contender.profile['fullname'] = item.contender.profile.first_name + ' ' + item.contender.profile.last_name
                    }

                })

                setPoints(response.data._items)
                setRawData(response.data._items)
            })).then(() => setLoading(false))
    }, [reload])

    useEffect(() => {
        const _leaderBoard = {}
        let _leaderList = []
        rawData.map(row => {
            if (!(row.contender._id in _leaderBoard)) {
                _leaderBoard[row.contender._id] = {
                    "name": row.contender.username,
                    "points": row.points
                }
            }
            else {
                _leaderBoard[row.contender._id].points += row.points
            }
        })

        Object.keys(_leaderBoard).map(item => _leaderList.push(_leaderBoard[item]))
        _leaderList.sort((a, b) => (a.points < b.points) ? 1 : -1)
        setLeaderBoard(_leaderList)
    }, [rawData])


    const rowClickHandler = (row) => {

    }

    const deletePointsHandler = (id) => {
        superApi.delete('points/' + id).then(response => {
            setReload(!reload)
        })
    }

    const movePointsHandler = (row) => {
        superApi.patch('points/' + row._id, { "company": row.contender.company }).then(response => {
            setReload(!reload)
        })
    }

    const handleSelect = ranges => {

        setDateRangeSelection(ranges.selection)
        const prevTableData = rawData.filter((data) => moment(data.day).isBetween(ranges.selection.startDate, ranges.selection.endDate, 'day', '[]'))

        setPoints(prevTableData)
    }

    const columns = [
        {
            name: 'Contender',
            selector: 'contender.profile.fullname',
            sortable: true,
            maxWidth: '20px',
        },
        {
            name: 'Day',
            selector: 'day',
            sortable: true,
            cell: row => <Moment format='DD/MM/YY HH:mm' date={row.day || row._created} />,
            maxWidth: '20px',
        },
        {
            name: 'Category',
            selector: 'category',
            sortable: true,
            maxWidth: '20px',
        },
        {
            name: 'Activity',
            selector: 'activity.title',
            sortable: true,
            maxWidth: '30px',
        },
        {
            name: 'Notes',
            selector: 'notes',
            sortable: true,
            maxWidth: '300px',
            minWidth: '100px'
        },
        {
            name: 'Points',
            selector: 'points',
            sortable: true,
            maxWidth: '50px',
            minWidth: '50px'
        },
        {
            name: 'Entered',
            cell: row => <Moment format='DD/MM/YY HH:mm' date={row._created} />,
            selector: '_created',
            maxWidth: '20px',

        },
        {
            name: 'Action',
            cell: row => (<React.Fragment><Link to="#" onClick={() => deletePointsHandler(row._id)}>Delete</Link>
                {row.company !== row.contender.company && <React.Fragment><span> | </span><Link to="#" onClick={() => movePointsHandler(row)}>Fix</Link></React.Fragment>}</React.Fragment>),
            sortable: false,
            maxWidth: '10px',
        }
    ];

    const selectionRange = {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    }

    if(loading) {
        return (
            <div>Loading..</div>
        )
    }

    return (
        <div className="PointsTable">
            {showFilter ? <DateRangePicker
                months={2}
                direction="vertical"
                ranges={[dateRangeSelection]}
                onChange={handleSelect}
                editableDateInputs
                /> : null}
            <div style={{
                // position: 'absolute',
                display: 'flex',
                justifyContent: 'space-between',
                // right: '25px',
                // width: '100vw'
            }}>

                <Button onClick={() => setShowFilter(!showFilter)}>Filter</Button>
            <CSVLink data={csvData} filename={"points_data.csv"} target="_blank">CSV</CSVLink>
            </div>

            <DataTable

                columns={columns}
                data={points}
                defaultSortField="date"
                keyField="_id"
                onRowClicked={(row) => rowClickHandler(row)}
                pagination
                expandableRows
                expandableRowsComponent={<ExpanableComponent />}
                striped
                highlightOnHover
                dense
                className="points-table"
                style={{ display: 'block' }}
            />
            <div className="leaderBoard">
                {leaderBoard ? leaderBoard.map(item => <p key={item.name}>{item.name + ': ' + item.points}</p>) : null}
            </div>
        </div>
    );
}

export default PointsTable;