import React, { useState, useEffect } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
import { Table, Button } from 'reactstrap';
import superApi from '../../../Api/Api';
import TemplateForm from '../../../Forms/TemplateForm';
import Template from './Template/Template';

const Templates = (props) => {

    const [tableData, setTableData] = useState([])
    const [showForm, setShowForm] = useState(false);
    const [reload, setReload] = useState(true)

    useEffect(() => {
        setShowForm(false)
        superApi.get('/template').then((response) => {
            setTableData(response.data._items);
        })
    }, [reload])
    const TABLEDATA = tableData.map(item => {
        const TASKS = item.tasks?.map(t => {
            const task = props.tasks.filter((_t) => {

                if (_t._id == t) {
                    return _t
                }

            })
            if(task[0] !== undefined) {
                return (
                    <li key={t}><Link to={props.match.url + '/' +t}>{task[0].title}</Link></li>
                )
            }
        })


        return (
            <tr key={item._id}>
                <td><Link to={props.match.url + "/templates/" + item._id}>{item.name}</Link></td>
                <td>{item.default ? 'Yes' : 'No'}</td>
                <td><ul>{TASKS}</ul></td>
            </tr>
        )
    })



    return (
        <Switch>
            <Route path={props.match.url + "/templates/:template"} render={(props) => <Template {...props} />} />
            <React.Fragment>
                <div className="heading-wrapper d-flex justify-content-between align-items-center">
                    <p className="display-4">Templates</p>
                    <Button onClick={() => setShowForm(true)} color="outline-info">Add</Button>

                </div>
                <div className="add-form-wrapper">
                    <TemplateForm reload={reload} setReload={setReload} template={null} tasks={props.tasks} hidden={!showForm} />

                </div>

                <Table>

                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Default</th>
                            <th>Activities</th>

                        </tr>
                    </thead>
                    <tbody>
                        {TABLEDATA}
                    </tbody>
                </Table>
            </React.Fragment>
        </Switch>
    );
}

export default Templates;