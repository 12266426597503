import React, {useState} from 'react';
import { Route } from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    Collapse,
    NavbarToggler,
    Nav,
    NavItem,
    NavLink


} from 'reactstrap';
import MyNav from './Nav/Nav';
import Home from './Home/Home';
import Aux from '../HOC/Aux';
import { Container, Row, Col } from 'reactstrap';
import Activities from './Activities/Activities';
import Companies from './Companies/Companies';
import Categories from './Categories/Categories';
import Vouchers from './Vouchers/Vouchers';
import Users from "./Users/Users";

const styles = {
    'leftNav': {
        // "backgroundColor": "black",
        "color": "white",
        "paddingTop": "20px",
        "height": "100vh",
        "marginLeft": "-15px",
        "marginRight": "-15px",

    },
    'topNav': {
        "width": "100%",

    },
    'main': {
        "height": "100vh"
    }
}
const Layout = (props) => {

    const [menuOpen, setMenuOpen] = useState(false)
    return (
        <Aux>
            <Container fluid>

                <Row>


                    {/* <Navbar style={styles.topNav} expand={true} color="info"><NavbarBrand>Admin</NavbarBrand></Navbar> */}
                    <Navbar
                        color="info"
                        container="fluid"
                        expand="sm"
                        full
                        dark
                        style={styles.topNav}
                    >
                        <NavbarBrand href="/">
                            TWBC Admin
                        </NavbarBrand>
                        <NavbarToggler onClick={() => setMenuOpen(!menuOpen)} />
                        <Collapse navbar isOpen={menuOpen}>
                            <Nav
                                className="me-auto"
                                navbar
                            >
                                <NavItem><NavLink href="/companies">Companies</NavLink></NavItem>
                                <NavItem><NavLink href="/activities">Activities</NavLink></NavItem>
                                <NavItem><NavLink href="/categories">Categories</NavLink></NavItem>
                                <NavItem><NavLink href="/users">Users</NavLink></NavItem>
                                <NavItem><NavLink href="/reports">Reports</NavLink></NavItem>
                                <NavItem><NavLink href="/vouchers">Vouchers</NavLink></NavItem>

                            </Nav>

                        </Collapse>
                    </Navbar>

                </Row>
                <Row style={styles.main}>
                    {/* <Col md="2"> */}

                    {/* <MyNav expand={false} style={styles.leftNav} class="navbar-light bg-secondary"  /> */}
                    {/* </Col> */}

                    <Col md="10">
                        <Route path="/" exact render={() => <Home />} />
                        <Route path="/companies" render={(props) => <Companies {...props} />} />
                        <Route path="/activities" render={(props) => <Activities {...props} />} />
                        <Route path="/categories" render={(props) => <Categories {...props} />} />
                        <Route path="/users" render={(props) => <Users {...props} />} />
                        <Route path="/vouchers" render={(props) => <Vouchers {...props} />} />
                    </Col>

                </Row>
            </Container>
        </Aux>
    );
}

export default Layout;